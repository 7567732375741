@each $color,
$value in $theme-colors {
    .link-#{$color} {
        color: $value !important; // stylelint-disable-line declaration-no-important

        @if $link-shade-percentage !=0 {

            //   &:hover,
            //   &:focus {
            &:hover {
                //color: if(color-contrast($value)==$color-contrast-light, shade-color($value, $link-shade-percentage), tint-color($value, $link-shade-percentage)) !important; // stylelint-disable-line declaration-no-important
                color: tint-color($value, $link-shade-percentage)!important;
            }
        }
    }
}